import { getAppConfig } from '@services/config';

import { HttpLoggerData, LogHttpTransporterInterface, LogTransportOptions } from '@app-types/logger';

import { isServer } from '@helpers/config';

import { LoggerService } from './logger.service';

type LogPrisme = {
    st: string;
    requestUri: string;
    httpMethod: string;
    httpStatus: number;
    Location?: string;
    urlPattern: string;
    duree?: number;
    output?: string;
    input?: string;
    headers?: object;
    level?: 'LOG' | 'INFO' | 'WARN' | 'ERROR';
    appelant: 'server' | 'front';
};

abstract class LogHttpTransporterAbstract implements LogHttpTransporterInterface {
    protected logCached = false;
    public constructor(options: LogTransportOptions) {
        this.logCached = options.logCached || false;
    }
    abstract send(data: HttpLoggerData): void;
}

class HttpVoidTransporter extends LogHttpTransporterAbstract {
    public send(_: HttpLoggerData): void {
        // nothing
    }
}
class HttpConsoleTransporter extends LogHttpTransporterAbstract {
    logger: LoggerService;
    public constructor(options: LogTransportOptions) {
        super(options);
        this.logger = new LoggerService();
    }

    public send(data: HttpLoggerData) {
        if (data.cached) {
            this.logger.log('✅ HTTP Success', data);
        } else if (data.status > 399) {
            this.logger.error('HTTP Error', data);
        } else {
            this.logger.info('HTTP Request', data);
        }
    }
}

export class HttpPrismeTransporter extends LogHttpTransporterAbstract {
    public send(data: HttpLoggerData) {
        if (!this.logCached && data.cached) {
            return;
        }

        this.displayLog(this.mapToLogFormatPrisme(data));
    }
    private mapToLogFormatPrisme(data: HttpLoggerData): LogPrisme {
        const url = new URL(data.uri);
        const urlPattern = `${url.protocol}//${url.host}${url.pathname}`;
        return {
            st: 'portailvente_web_wall',
            requestUri: data.uri,
            urlPattern: urlPattern,
            httpMethod: data.method.toUpperCase(),
            httpStatus: data.status,
            headers: data.headers,
            duree: data.duration,
            input: data.input,
            output: data.output,
            level: data.status > 399 ? 'ERROR' : 'INFO',
            appelant: 'server',
        };
    }
    private displayLog(data: LogPrisme): void {
        console.log(JSON.stringify(data));
    }
}

const transporterFactory = (): LogHttpTransporterInterface => {
    // log only server side prisme or standart console.log
    const appConfig = getAppConfig();
    const logOption: LogTransportOptions = {
        logCached: appConfig.logger.http.logCached,
    };

    if (isServer()) {
        return appConfig.logger.http.prisme
            ? new HttpPrismeTransporter(logOption)
            : new HttpConsoleTransporter(logOption);
    }

    return new HttpVoidTransporter(logOption);
};
export const transporter = transporterFactory();
