import { ObjectFilterType } from '@bytel/product-wall/types';

import { getSessionVariable } from '@services/storage';

import { EligibilityConfigType, WallConfigFileType, WallConfigType, WallType } from '@app-types/config';

import { generateConfig } from '@helpers/config';

import fs from 'fs';
import path from 'path';

let wallMemoryConfig: string;
let eligibilityMemoryConfig: string;
let featuresMemoryConfig: string;

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'portailvente/product-wall'?: WallConfigFileType;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'portailvente/eligibility'?: EligibilityConfigType;
        portailvente?: Record<string, boolean>;
    }
}

export const getWallConfigContent = (): string => {
    if (wallMemoryConfig === undefined) {
        wallMemoryConfig = fs.readFileSync(path.resolve('./public', 'walls-config.json'), 'utf8');
    }

    return wallMemoryConfig;
};

export const getWallConfig = (type: WallType): WallConfigType => {
    let wallsConfig: WallConfigFileType;
    const isServer = typeof window === 'undefined';

    if (isServer) {
        wallsConfig = JSON.parse(getWallConfigContent());
    } else {
        wallsConfig = window['portailvente/product-wall']!;
    }

    const filtersObject: WallConfigType['filtersObject'] = {};

    wallsConfig?.[type]?.filters?.forEach((filter) => {
        if (filter.values) {
            const values: ObjectFilterType['values'] = {};
            filter.values.forEach((value) => {
                values[value.key] = value;
            });

            filtersObject[filter.key] = { ...filter, values };
        } else {
            filtersObject[filter.key] = filter;
        }
    });

    return { filtersObject, ...wallsConfig?.[type] };
};

export const getEligibilityConfigContent = (): string => {
    if (eligibilityMemoryConfig === undefined) {
        eligibilityMemoryConfig = fs.readFileSync(path.resolve('./public', 'eligibility-config.json'), 'utf8');
    }

    return eligibilityMemoryConfig;
};

export const getEligibilityConfig = (): EligibilityConfigType => {
    const isServer = typeof window === 'undefined';

    if (isServer) {
        return JSON.parse(getEligibilityConfigContent());
    } else {
        return window['portailvente/eligibility']!;
    }
};

export const getFeaturesConfigContent = (): string => {
    if (featuresMemoryConfig === undefined) {
        featuresMemoryConfig = fs.readFileSync(path.resolve('./public', 'features-config.json'), 'utf8');
    }

    return featuresMemoryConfig;
};

export const getFeaturesConfig = (): Record<string, boolean> => {
    const isServer = typeof window === 'undefined';

    // Overwrite feature flags via session for dynamic changes.
    // This allows for the alteration of feature flags at runtime based on session variables.
    // Useful for testing or conditional feature deployment.
    // See feature module in https://gitlab.int.nbyt.fr/A360/the-monkeys-paw.
    if (isServer) {
        return JSON.parse(getFeaturesConfigContent());
    } else {
        const sessionFeatures = JSON.parse(getSessionVariable('portailvente_features') || '{}');
        const windowFeatures = window.portailvente!;

        return { ...windowFeatures, ...sessionFeatures };
    }
};

export const getAppConfig = () => {
    return generateConfig();
};
