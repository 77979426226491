export class LoggerService {
    public log(msg: unknown, ...args: unknown[]): void {
        console.log(msg, ...args);
    }

    public info(msg: unknown, ...args: unknown[]): void {
        console.info(`ℹ️ ${msg}`, ...args);
    }

    public error(msg: unknown, ...args: unknown[]): void {
        console.error(`⚠️ ${msg}`, ...args);
    }
}

export const logger = new LoggerService();
