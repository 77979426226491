import { getAppConfig } from '@services/config';
import { transporter } from '@services/loggers/http-transporter.service';

import { HttpLoggerData, LogHttpTransporterInterface } from '@app-types/logger';

import { BytelDefaultContext } from '@helpers/graphql-request';

import { Operation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils/throwServerError';

class ApolloLoggerService {
    private transporter: LogHttpTransporterInterface;
    private appConfig;

    public constructor(transporter: LogHttpTransporterInterface) {
        this.transporter = transporter;
        this.appConfig = getAppConfig();
    }

    public logError(error: ErrorResponse) {
        const networkError = error.networkError as ServerError;
        const graphQLError = error.graphQLErrors;

        const data: HttpLoggerData = {
            method: 'GET',
            status: 500,
            uri: this.appConfig.graphQL.url,
            input: JSON.stringify({
                body: error.operation?.query?.loc?.source.body,
                variables: error.operation?.variables,
            }),
            output: JSON.stringify(error.response?.data),
        };

        if (networkError) {
            data.status = networkError.statusCode;
            data.output = JSON.stringify(networkError.result);
        }

        if (graphQLError) {
            data.output = JSON.stringify(error.response?.errors);
        }

        this.transporter.send(data);
    }

    public logResponse(operation: Operation, response: unknown) {
        const context: BytelDefaultContext = operation.getContext();

        const data: HttpLoggerData = {
            method: 'GET',
            status: context.response.status,
            cached: context.fromCache,
            uri: this.appConfig.graphQL.url,
            duration: context.metadata ? Date.now() - context.metadata.startDate : 0,
            input: JSON.stringify({
                body: operation?.query.loc?.source.body,
                variables: operation.variables,
            }),
            headers: context.headers,
            output: response ? JSON.stringify(response) : '',
        };

        this.transporter.send(data);
    }
}

export const apolloLoggerService = new ApolloLoggerService(transporter);
