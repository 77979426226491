import { BytelAxiosRequestConfig } from '@services/http/axios-instance-factory';
import { transporter } from '@services/loggers/http-transporter.service';

import { HttpLoggerData, LogHttpTransporterInterface } from '@app-types/logger';

import { AxiosError } from 'axios';
import { CacheAxiosResponse } from 'axios-cache-interceptor';

class AxiosLoggerService {
    private transporter: LogHttpTransporterInterface;

    public constructor(transporter: LogHttpTransporterInterface) {
        this.transporter = transporter;
    }
    public logHttpError(error: AxiosError) {
        const httpData: Partial<HttpLoggerData> = {
            status: error.response?.status || 0,
            output: JSON.stringify({
                message: error.message,
                description: error.response?.data,
            }),
        };

        this.sendLog(httpData, error.config as BytelAxiosRequestConfig);
    }

    public logHttpResponse(res: CacheAxiosResponse) {
        const httpData: Partial<HttpLoggerData> = {
            status: res.status,
            output: JSON.stringify(res.data),
            cached: res.cached,
        };

        if (res.cached) {
            httpData.status = 304;
        }
        this.sendLog(httpData, res.config as BytelAxiosRequestConfig);
    }

    // @TODO: continue mutualize
    private sendLog(httpData: Partial<HttpLoggerData>, config: BytelAxiosRequestConfig): void {
        httpData.uri = config.url ?? '';
        httpData.input = config.data?.toString();
        httpData.method = config.method ?? '';
        httpData.duration = Date.now() - config.metadata.startDate;
        httpData.headers = config.headers;

        this.transporter.send(httpData as HttpLoggerData);
    }
}

export const axiosLoggerService = new AxiosLoggerService(transporter);
