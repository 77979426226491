import { transporter } from '@services/loggers/http-transporter.service';

import { HttpLoggerData, LogHttpTransporterInterface } from '@app-types/logger';

class FetchLoggerService {
    private transporter: LogHttpTransporterInterface;
    private readonly delayToBeConsideredCached = 8;

    public constructor(transporter: LogHttpTransporterInterface) {
        this.transporter = transporter;
    }

    public async logHttpError(error: Error, request: Request, start: number) {
        const httpData: Partial<HttpLoggerData> = {
            status: undefined,
            duration: Date.now() - start,
            output: JSON.stringify({
                message: error.message,
            }),
        };

        await this.sendLog(httpData, request);
    }

    public async logHttpResponse(res: Response, request: Request, start: number) {
        const duration = Date.now() - start;
        const isCached = duration <= this.delayToBeConsideredCached;
        const httpData: Partial<HttpLoggerData> = {
            status: isCached ? 304 : res.status,
            duration: duration,
            cached: isCached,
            output: await res.clone().text(),
        };

        await this.sendLog(httpData, request);
    }

    private async sendLog(httpData: Partial<HttpLoggerData>, request: Request): Promise<void> {
        const headers: Record<string, string> = {};
        request.headers.forEach((value, name) => {
            headers[name] = value;
        });

        httpData.uri = request.url;
        httpData.input = await request.text();
        httpData.method = request.method;
        httpData.headers = headers;

        this.transporter.send(httpData as HttpLoggerData);
    }
}

export const fetchLoggerService = new FetchLoggerService(transporter);
